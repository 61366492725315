<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP CREW VIEW

type    : view

uses    : header-view

route   : /crews/view/:uuid
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div>
    <header-view
      title="Work Crew"
      previous_page="/crews"
      :allow_edit="true"
      :edit_url="'/crews/edit/' + crew.uuid" />

    <v-container class="ma-3">
      <v-layout row>
        <v-flex md2 class="text-xs-right pr-2">
          <b>Crew Name</b>
        </v-flex>
        <v-flex md4>
          {{ crew.name }}
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex md2 class="text-xs-right pr-2">
          <b>Crew Status</b>
        </v-flex>
        <v-flex md4>
          {{ crew.status }}
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex md2 class="text-xs-right pr-2">
          <b>Foreman</b>
        </v-flex>
        <v-flex md4>
          {{ foreman_name }}
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios';
  import HeaderView from '@/components/header-view';

  export default {
    name: 'CrewView',
    components: {
      HeaderView,
    },
    props: {
      uuid: {
        default: '',
        type: String,
      },
    },
    data() {
      return {
        crew: {},
        foreman_name: '',
      };
    },
    async mounted() {
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();
      axios({
        method: 'GET',
        url: '/service_tenants/crews/' + this.uuid,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((crewResult) => {
          // console.log( "heres the crew result" )
          // console.log( crewResult.data )
          this.crew = crewResult.data;
          this.selectedForeman = this.crew.foreman_uuid;
          // console.log( "calling this url" )
          // console.log( '/service_users/users/' + this.crew.foreman_uuid )
          axios({
            method: 'GET',
            url: '/service_users/users/' + this.crew.foreman_uuid,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then((userResult) => {
              // console.log( "user result" )
              // console.log( userResult )
              // console.log( "heres the crew now" )
              // console.log( this.crew.name )
              // console.log( this.crew.status )
              this.foreman_name = userResult.data.name;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
